export namespace AppValues {
	export enum ArticleGroups {
		ADITAM_EGH = 'ADITAM. EQUIPOS GH',
		BATTERY_CH = 'CARGADOR BATERIAS',
		BATTERY_GH = 'BATERIAS GH',
		EQUIPMENT_GH = 'EQUIPO GH',
		FACILITY = 'FACILITY'
	}

	export enum ArticleTypes {
		ATTACHMENT_LOG = 'ATTACHMENTLOG',
		BATTERY_LOG = 'BATERIALOG',
		CHARGER_LOG = 'CARGADORLOG',
		EQUIPMENT_LOG = 'EQUIPOLOG',
		OTHER = 'OTRO'
	}

	export enum DataTableExportDataFilterType {
		PERIOD,
		QUANTITY
	}

	export enum EquipmentTypes {
		AP_ELECT = 'APELECT',
		ATTACHMENT = 'ATTACHMENT',
		GH_ART = 'GHART',
		GH_ELECT = 'GHELECT',
		GH_GAS = 'GHGAS',
		HEATER = 'CALEFACTOR',
		OPV_ELECT = 'OPVELECT',
		OTHER = 'OTRO',
		REACH_ELECT = 'REACHELECT',
		TRACTOR_ELECT = 'TRACTORE',
		TRANSP_ELECT = 'TRANSPELECT',
		WAGON = 'CARRO'
	}

	export enum ObjectIds {
		Module10 = '606a23a1a758634128c7bd73',
		Module20 = '606a2401a758634128c7bd74',
		Module30 = '606a2450a758634128c7bd75',
		Module40 = '61e8652b9082fe99eb5f50fd',
		Module41 = '6092cf0c53b1f928ec30f2ca'
	}

	export enum PeriodsGroupList {
		IN_DAYS,
		IN_MONTHS
	}

	export enum PeriodsChoiceList {
		CURRENT_MONTH,
		CURRENT_YEAR,
		LAST_12_MONTHS,
		LAST_15_DAYS,
		LAST_30_DAYS
	}

	export enum PeriodsChoiceFriendlyTextList {
		CURRENT_MONTH  = 'Mes Actual',
		CURRENT_YEAR   = 'Año Actual',
		LAST_12_MONTHS = 'Ultimos 12 Meses',
		LAST_15_DAYS   = 'Ultimos 15 Días',
		LAST_30_DAYS   = 'Ultimos 30 Días'
	}

	export enum Strings {
		DEFAULT_EMPTY_STRING = '---',
		PREFIX_LOCAL_STORAGE = 'localstorage.kreis92k3mnzbcda.',
		TEXT_EQUIPMENTS      = 'Equipments',
		TEXT_FRIENDLY_YES    = 'Sí',
		TEXT_FRIENDLY_NO     = 'No',
		TEXT_KREIS           = 'KREIS',
		TEXT_WORKAREAS       = 'WorkAreas'
	}

	export enum QuantityChoiceList {
		LAST_10_ITEMS,
		LAST_20_ITEMS,
		LAST_30_ITEMS,
		LAST_50_ITEMS,
		ALL_ITEMS
	}

	export enum QuantityChoiceFriendlyTextList {
		LAST_10_ITEMS  = 'Ultimo 10 Items',
		LAST_20_ITEMS   = 'Ultimos 20 Items',
		LAST_30_ITEMS = 'Ultimos 30 Items',
		LAST_50_ITEMS   = 'Ultimos 50 Items',
		ALL_ITEMS   = 'Todos los Items'
	}
}